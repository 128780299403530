.star-check--sm {
	width: 29px;
	height: 29px;
}

.icon--adobe-reader {
	width: 20px;
	height: 20px;
	vertical-align: sub;
}

.icon--crba {
	font-size: 200px;
}

.icon--bg-circle {
	position: relative;
	width: 50px;
	height: 50px;
	border: 2px solid var(--vc-gray-300);
	border-radius: 50px;
	background: $white;
	display: inline-block;
	vertical-align: middle;
	z-index: 3;

	svg {
		position: absolute;
		top: 8px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 28px;
	}

}

// Fontawesome-specific
.fa-3x {
	vertical-align: -19px;
}
