.agency {

	.agency__address {
		margin-bottom: 15px;
		address {
			display: inline;
		}

		svg {
			margin-right: 5px;
		}
	}

	.address-one, .address-two {
		display: block;
		padding-left: 25px;
	}

	.agency__fax {
		margin-bottom: 20px;
	}

	.agency-name {
		font-size: 18px;
		font-weight: 700;
	}

	.agency__phone, .agency__fax {
		svg {
			margin-right: 5px;
		}
	}

	.agency__site {
		svg {
			margin-left: 6px;
			font-size: 13px;
			vertical-align: baseline;
		}
	}

	.city {
		padding-left: 25px;
	}

}

