#pin {
    -webkit-text-security: disc;
    -moz-webkit-text-security: disc;
    -moz-text-security: disc;
}

#whichForm {
	display: block;
	opacity: 1;

	&.hidden {
		display: none;

		&.showing {
			display: block;
			opacity: 0;
		}
	}

	&.show {
		transition: opacity .5s ease;
	}

}