.badge {
	font-size: 11px;
	text-transform: uppercase;
	line-height: normal;

	@include media-breakpoint-up(sm) {
		font-size: var(--vc-badge-font-size);
	}
}

.badge-danger {
	color: var(--vc-red);
	background-color: var(--vc-white);
	border: 1px solid var(--vc-red);
}

.badge-success {
	color: var(--vc-black);
	background-color: var(--vc-success);
	border: 1px solid var(--vc-green-900);
}

.badge-warning {
	color: var(--vc-black);
	background-color: var(--vc-warning);
	border: 1px solid var(--vc-yellow-900);
}

.badge-info {
	color: var(--vc-black);
	background-color: var(--vc-slate);
	border: 1px solid var(--vc-slate-600);
}
