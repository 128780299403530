.site-header {
	position: relative;
	background-color: #fff;
	z-index: 100;

	.navbar-brand {
		border-bottom: none;

		&:hover, &:focus {
			border-bottom: none;
		}

		@include media-breakpoint-down(md) {
			margin-left: 10px;
		}

	}
	img {
		//width: 115px;
		//height: 45.516px;
		//@include media-breakpoint-up(md) {
			width: 165px;
			height: 65.3125px;
		//}
	}
}