body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	font-size: 14px;
}

main {
	flex: 1;

	section:nth-of-type(even) {
		background-color: var(--vc-gray-100);
	}
}

a {
	transition: all $speed $ease;
	text-decoration: none;
	border-bottom: 1px solid var(--vc-link-color);
	font-weight: 700;

	&:hover, &:focus {
		transition: all $speed $ease;
		text-decoration: none;
		border-bottom: 1px solid var(--vc-link-hover-color);
		font-weight: 700;
	}
}

aside {
	@include media-breakpoint-down(lg) {
		margin-top: 50px;
	}

	.site-logo {
		max-width: 100%;
	}
}

.col > img {
	max-width: 100%;
}

.sideby {
	display: flex;
	font-size: 14px;
	flex-direction: row;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}

	h3 {
		margin-bottom: 0;
		font-size: $h5-font-size;
		color: $red;
	}
}

.sideby--hcenter {
	.sideby-col {
		align-items: center;
		text-align: center;
	}
}

.sideby-col {
	display: flex;
	margin-right: 20px;
	margin-bottom: 0;
	padding: 10px 20px 10px 0;
	align-items: flex-start;
	flex: 1 0 0%;
	flex-direction: column;
	border-right: 1px solid $slate-light;

	@include media-breakpoint-down(sm) {
		margin-right: 0;
		border-right: none;
	}

	&:last-child {
		margin-right: 0;
		padding-right: 0;
		border-right: none;
	}
}

.todo {
	font-size: 22px;
	font-family: $font-family-monospace;
	color: fuchsia;
	background-color: black;
}


// Custom fontawesome icon fix
svg[data-prefix="fak"] {
	width: auto !important;
}