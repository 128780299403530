.multi-list-group {

	.list-group {
		margin-bottom: 15px;
	}
}

.list-style-none {
	list-style: none;
}

.checks {
	margin: 0;
	padding: 0 30px 0 0;
	list-style: none;

	@include media-breakpoint-up(md) {
		margin-top: 45px;
		padding: 0 30px;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom: 90px;
	}

	> li {
		position: relative;
		margin: 0 0 15px 0;
		padding: 0 0 0 50px;
		font-size: 16px;
		line-height: 1.25;

		&:before {
			position: absolute;
			display: inline-block;
			width: 29px;
			height: 29px;
			top: 1px;
			left: 0;
			content: "";
			background: url(../../images/atomic/icons/star-check.svg) no-repeat center center;
			background-size: 100% 100%;
		}
	}

}

.checks--sm {
	padding: 0;

	@include media-breakpoint-up(md) {
		margin-top: 20px;
		padding: 0 30px;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom: 20px;
	}

	> li {
		padding-left: 30px;

		&:before {
			width: 17px;
			height: 12px;
			top: 4px;
			background: url(../../images/atomic/icons/checkmark.svg) no-repeat center center;
		}
	}
}

.verified-docs-list {
	padding: 0;
	list-style: none;

	> li {
		display: flex;
		justify-content: space-between;

		&:not(:last-child) {
			padding-bottom: 5px;
			border-bottom: 1px dashed var(--vc-gray-500);
		}
		&:not(:first-child) {
			padding-top: 5px;
		}
	}
}