.card {
	box-shadow: $box-shadow;
}

.card--no-shadow {
	box-shadow: none;
}

.card--no-border {
	border: none;
}

.card_submitted-docs {
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.card--group {
	&:first-child {
		margin-bottom: 0;
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	&:not(:first-child) {
		margin-top: 0;
		border-top-style: dotted;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top-width: 3px;
	}
}

.card--group__start {
	margin-bottom: 0!important;
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.card--group__middle {
	margin-bottom: 0!important;
	border-bottom: none;
	border-top: 2.5px dashed var(--vc-gray-500);
	border-radius: 0;
}

.card--group__last {
	border-top: 2.5px dashed var(--vc-gray-500);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.card__content {
	display: inline-block;
	justify-content: space-between;
	margin-left: 5px;

	@include media-breakpoint-up(sm) {
		margin-left: 20px;
	}
}

.card__misc {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.card__status {
	margin-top: 8px;

	@include media-breakpoint-up(sm) {
		margin-top: 15px;
	}
}

.card__thumbnail {
	float: left;
	border: 0.0625rem solid var(--vc-gray-500);
	border-radius: 0.25rem;
	height: 50px;
	overflow: hidden;
	position: relative;
	width: 50px;

	img {
		width: 50px;
	}
}

.card--top-border {
	border-top: 1px solid var(--vc-black);
}

.card--bottom-border-radius {
	border-radius: 0; // reset
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.card-header {
	//background-color: var(--vc-gray-200);
	//border-bottom: 1px solid var(--vc-gray-800);
	padding: var(--vc-card-cap-padding-y) var(--vc-card-cap-padding-x);

	h2 {
		margin-bottom: 0;
		font-size: 16px;
		color: $secondary;
		text-transform: uppercase;
	}

	// This is currently in use for Phase 1
	h3 {
		font-size: 20px;
		margin-bottom: 0;
	}
}

.card-header__icon {
	border-radius: 100px;
	background-color: var(--vc-white);
	border: 1px solid var(--vc-gray-500);

	&.clipboard {
		padding: 10px 15px;
	}
}

.card-body {
	padding: 10px;
	@include media-breakpoint-up(md) {
		padding: var(--vc-card-spacer-y) var(--vc-card-spacer-x);
	}
}

.card-title {
	@include font-size(20px);
	//line-height: 36px;

	//@include media-breakpoint-down(lg) {
	//	line-height: 20px;
	//}
}

.card-title--red {
	color: var(--vc-primary);
}

.card-title--verify-identity {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
}

.card-footer {
	padding: 10px;
	@include media-breakpoint-up(md) {
		padding: var(--vc-card-spacer-y) var(--vc-card-spacer-x);
		padding-bottom: var(--vc-card-cap-padding-x); // using the x-axis value for bottom y-value
	}
}

.card-footer__controls {
	display: grid;
	gap: 8px;

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: flex-end;
	}
}

.card.card--highlight:before {
	background: #354757;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	display: block;
	content: "";
	height: 17px;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

//** Override Bootstrap styles if in .sec--cert-types
.sec--cert-types {
	.card {
		height: 100%;
	}

	.card-body {

	}
	.card-text {
		position: relative;
		max-height: 73px;
		overflow: hidden;
		transition: max-height 0.3s $ease;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			opacity: 1;
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			transition: opacity 0.4s $ease;
		}

		&.show {
			max-height: 600px;
			overflow: visible;
			transition: max-height 0.3s $ease;

			&:after {
				opacity: 0;
				transition: none;
			}
		}
	}

	.read-more {
		margin-bottom: 0;
	}
}

//====== @END Bootstrap style overrides


.card--agency, .card--intl-agency {
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
	padding: 10px;
	overflow: hidden;

	@include media-breakpoint-up(sm) {
		min-height: 90%;
	}

	.collapse {
		display: none;
		flex-direction: column;
		flex: 1;
		&.show {
			display: flex;
		}
	}

	.card__title {
		margin-bottom: 0;

		svg {
			font-size: 18px;

			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
	}

	.card__body {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}

		&.show {
			display: flex;
		}
	}

	.card__footer {
		margin-top: 15px;

		//@include media-breakpoint-up(sm) {
		//	display: flex;
		//	flex-grow: 1;
		//}
	}

	.card--agency__details {
		padding-top: 10px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
		@include media-breakpoint-up(sm) {
			flex: 1 0 60%;
		}
	}

	.card__agency-name {
		font-size: 20px;
		margin-bottom: 0;
	}
	.card__address {
		display: flex;
	}

	address {
		position: relative;
		padding-left: 10px;
		top: -2px;
		font-size: 14px;
	}

	.address-one, .address-two {
		display: block;
	}

	.card__phone, .card__fax, .card__link {
		display: flex;
		align-items: baseline;
		font-size: 14px;
	}

	.card__learn-more {
		font-size: 14px;

		// Only needed if the agency cards are more than one to a line
		//@include media-breakpoint-up(sm) {
		//	margin-top: auto;
		//	flex-grow: 1;
		//}

		svg {
			margin-right: 5px;
		}
	}

	.card__link {
		a {
			margin-left: 10px;
		}
	}

	.phone-number, .fax-number {
		padding-left: 10px;
	}
}

.card--intl-agency {
	margin-bottom: 0;
	min-height: auto;
	height: 100%;

	.card--agency__details {
		margin-bottom: 8px;
		padding-top: 0;
	}

	.card__agency-name {
		margin-bottom: 8px;
	}
}

.card--agency__products {
	@include media-breakpoint-up(sm) {
		flex: 1 0 40%;
	}

	h4 {
		font-size: 18px;
	}

	.product {
		@include media-breakpoint-down(sm) {
			display: inline-block;
			margin-right: 16px;
		}
	}
}

.card-group .col:nth-of-type(even) {
	@include media-breakpoint-up(md) {
		.card--product {
			transform-origin: left;
		}
	}
}

.card-group .col:nth-of-type(odd) {
	@include media-breakpoint-up(md) {
		.card--product {
			transform-origin: right;
		}
	}
}

.card--product {
	padding-top: 15px;
	transition: all 0.3s;
	text-align: center;

	@include media-breakpoint-down(md) {
		padding: 16px;
		flex-direction: row;
		align-items: center;
		text-align: left;
	}

	&:hover, &:focus {
		transition: all 0.3s;
		transform: scale(1.1);
		border: 1px solid rgba(0, 0, 0, 0.125); // replaces the border from the a selector

		@include media-breakpoint-down(md) {
			transform: scale(1);
		}

		svg.svg-inline--fa * {
			fill: url(#lg-red);
		}


		h3 {
			color: $red;
			transition: color 0.3s;
		}

		.card-footer {
			.btn-arrow {
				border-radius: 80px;

				&.btn-arrow--to-red {
					@extend %vcgradient-primary;
					background-position: 90%;
					width: 170px;
					transition: width 0.5s, background-position 0.5s;

					@include media-breakpoint-down(sm) {
						width: 44px
					}
				}

				span {
					position: static;
					width: auto;
					height: auto;
					transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
					transform: scaleX(1);
					opacity: 1;

					@include media-breakpoint-down(sm) {
						position: absolute !important;
						margin: -.0625rem !important;
						padding: 0 !important;
						width: .0625rem !important;
						height: .0625rem !important;
						overflow: hidden !important;
						clip: rect(0, 0, 0, 0) !important;
						white-space: nowrap !important;
						border: 0 !important;
					}
				}

				svg {
					transform: translateX(0); // This fixes jerky animation of the arrow
				}
			}
		}
	}

	h3 {
		margin-bottom: 0;
		font-size: $h5-font-size;

		@include media-breakpoint-down(md) {
			font-size: 22px;
		}
	}

	svg.svg-inline--fa * {
		fill: var(--vc-black);
	}

	.svg-inline--fa {
		font-size: 48px;
		transition: transform 0.5s ease-in-out;
		transform: translateX(0);

		@include media-breakpoint-down(sm) {
			font-size: 36px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 0;
		}
	}

	.card-body {
		padding: 0 16px;
		@include media-breakpoint-up(sm) {
			padding: 16px 10px;
		}
	}

	.card-footer {
		padding-bottom: 15px;
		padding-top: 0;
		border-top: none;
		background-color: #FFFFFF;

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		.btn-arrow {
			padding: 12px 13px;
			border-radius: 80px;

			&.btn-arrow--to-red {
				@extend %vcgradient-secondary;
				width: 44px;
				line-height: 1;
				color: #FFFFFF;
				white-space: nowrap;
				transition: width 0.5s ease-in-out, padding 0.5s ease-in-out, background 1s ease-in-out, color 0.5s ease-in-out;

				svg.svg-inline--fa * {
					fill: var(--vc-white);
				}

				.svg-inline--fa {
					font-size: 18px;
				}

			}

			span {
				position: absolute;
				width: 0.0625rem;
				height: 0.0625rem;
				overflow: hidden;
				transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
				opacity: 0;
			}
		}
	}
}

.card--icon {
	margin-top: 20px;
	font-size: 48px;
}

