/*#INDWrap{
	display: none!important;
}*/

/* Shopper Approved css*/
.tooltip_text {
	h4 {
		color: #fff !important;
		font-size: 20px;
	}
	a {
		font-size: 13px;
	}
}
#shopper_approved {
	input[type="radio"] {
		box-sizing: content-box !important;
		&:before {
			box-sizing: content-box !important;
		}
		&:after {
			box-sizing: content-box !important;
		}
	}
	z-index: 32767;
	font: 14px arial,sans-serif !important;
	color: black !important;
	display: none;
	position: absolute;
	width: 100%;
	font: 14px !important;
	width: 100%;
	#sa_outer {
		margin: 10px auto;
		max-width: 4px !important;
		max-width: 480px !important;
	}
	#sa_rounded {
		background-color: white !important;
		padding: 0 0 20px 0 !important;
		border: 1px solid #ccc !important;
		-moz-box-shadow: 2px 2px 5px #888 !important;
		-webkit-box-shadow: 2px 2px 5px #888 !important;
		box-shadow: 2px 2px 5px #ccc !important;
		-moz-border-radius: 10px !important;
		-webkit-border-radius: 10px !important;
		-khtml-border-radius: 10px !important;
		border-radius: 10px !important;
		padding: 0 0 20px 0 !important;
	}
	#sa_header {
		border: 0 !important;
		text-align: left !important;
		padding: 20px 0 0 30px !important;
		vertical-align: middle !important;
		font-style: italic !important;
		font-size: 24px !important;
		border: 0 !important;
		text-align: left !important;
		padding: 20px 0 0 30px !important;
		vertical-align: middle !important;
		font-style: italic !important;
		font-size: 24px !important;
	}
	select {
		font-size: 16px !important;
	}
	#sa_close {
		border: 0 !important;
		display: block !important;
		float: right !important;
		margin: -10px 10px 0 0 !important;
		cursor: pointer;
	}
	#sa_ratings {
		text-align: left !important;
		padding: 0 30px !important;
		text-align: left !important;
		padding: 0 30px !important;
	}
	#sa_thankyou {
		text-align: left !important;
		padding: 0 30px !important;
		text-align: left !important;
		padding: 0 30px !important;
	}
	#sa_thankyou_no_email {
		text-align: center !important;
		padding: 0 30px !important;
		text-align: center !important;
		padding: 0 30px !important;
	}
	.sa_ratingBox {
		padding: 20px 0 0 0 !important;
		font: 14px arial,sans-serif !important;
		color: black !important;
		padding: 20px 0 0 0 !important;
		font: 14px !important;
	}
	select.sa_value {
		width: 200px !important;
		margin-top: 7px !important;
		width: 200px !important;
		margin-top: 7px !important;
	}
	textarea {
		border: 1px solid #bbb !important;
		color: gray;
		padding: 2px;
		width: 100% !important;
		border: 1px solid #bbb !important;
		color: gray;
		padding: 2px;
		width: 100% !important;
	}
	#sa_footer_img {
		float: right !important;
		display: block !important;
		float: right !important;
		display: block !important;
	}
	#sa_header_text {
		display: block;
		padding: 20px 0 0 0 !important;
	}
	input[type='checkbox'] {
		height: 1.00rem !important;
		width: .66rem !important;
	}
}
input#agree_customer {
	&::after {
		box-sizing: content-box !important;
	}
	box-sizing: content-box !important;
	&::before {
		box-sizing: content-box !important;
	}
}
#shopper_background {
	position: fixed !important;
	left: 0 !important;
	top: 0 !important;
	width: 100% !important;
	height: 100% !important;
	z-index: 32765 !important;
	background-color: #333333 !important;
	display: none;
	opacity: 0.40 !important;
	filter: alpha(opacity=40) !important;
	width: 0 !important;
	height: 0 !important;
	display: none;
}
.sa_star {
	cursor: pointer;
	background: url("https://www.shopperapproved.com/thankyou/simplestar.png") no-repeat !important;
	width: 24px !important;
	height: 24px !important;
	display: inline-block;
	cursor: pointer;
	background: url("https://www.shopperapproved.com/thankyou/simplestar.png") no-repeat !important;
	width: 24px !important;
	height: 24px !important;
	display: inline-block;
}
.sa_activestar {
	background-position: 0 -24px !important;
	background-position: 0 -24px !important;
}
input {
	font-size: 16px !important;
	font-size: 16px !important;
}
textarea {
	font-size: 16px !important;
	font-size: 16px !important;
}
#sa_lowrating {
	padding: 20px 0 !important;
	display: none;
	font: 16px arial,sans-serif !important;
	color: red !important;
	padding: 20px 0 !important;
	display: none;
	font: 16px !important;
	color: red !important;
	a {
		font: 16px arial,sans-serif !important;
		color: blue !important;
		cursor: pointer;
		font: 16px !important;
		color: blue !important;
		cursor: pointer;
	}
}
#sa_last_chance {
	display: none;
	font-size: smaller !important;
	color: red !important;
	padding: 0 0 6px 0 !important;
	display: none;
	font-size: smaller !important;
	color: red !important;
	padding: 0 0 6px 0 !important;
}
#shopper_submit {
	border: inherit !important;
	padding: inherit !important;
	background-color: transparent !important;
	color: transparent !important;
	background-image: url(https://www.shopperapproved.com/thankyou/images/submit-feedback.png) !important;
	width: 175px !important;
	height: 40px !important;
	border: inherit !important;
	padding: inherit !important;
	background-color: transparent !important;
	color: transparent !important;
	background-image: url(https://www.shopperapproved.com/thankyou/images/submit-feedback.png) !important;
	width: 175px !important;
	height: 40px !important;
}
#sa_comments {
	padding: 20px 0 !important;
	display: none;
	font: 16px arial,sans-serif !important;
	padding: 20px 0 !important;
	display: none;
	font: 16px !important;
}
#sa_optin {
	padding: 0 !important;
	display: none;
	font: 16px arial,sans-serif !important;
	color: black !important;
	padding: 0 !important;
	display: none;
	font: 16px !important;
	input[type="text"] {
		border: 1px solid #bbb !important;
		width: 300px !important;
		color: black !important;
		float: right;
		margin-right: 50px;
		border: 1px solid #bbb !important;
		width: 300px !important;
		float: right!important;
		margin-right: 50px;
	}
	.sa_optin {
		padding: 10px 0 !important;
		padding: 10px 0 !important;
	}
}
#sa_safe {
	font-weight: bold;
	font-weight: bold;
}
select {
	font-size: 16px !important;
}
input[type='radio'] {
	height: 1.00rem !important;
	width: .66rem !important;
}
#sa_tooptin {
	label {
		margin-right: 1.00rem;
	}
}
@media only screen and (max-width: 600px) {
	#sa_optin {
		input[type="text"] {
			margin-right: 0 !important;
		}
	}
}
@media only screen and (max-width: 540px) {
	#sa_optin {
		input[type="text"] {
			margin-right: 0 !important;
			width: 200px !important;
		}
	}
}
@media only screen and (max-width: 500px) {
	#shopper_approved {
		#sa_outer {
			margin: 0 !important;
			padding: 10px !important;
		}
	}
	#sa_name {
		width: 200 !important;
	}
	#sa_email {
		width: 200 !important;
	}
}
@media (max-width: 600px) {
	#sa_optin {
		input[type="text"] {
			margin-right: 0 !important;
		}
	}
}
@media (max-width: 540px) {
	#sa_optin {
		input[type="text"] {
			margin-right: 0 !important;
			width: 200px !important;
		}
	}
}
@media (max-width: 500px) {
	#shopper_approved {
		#sa_outer {
			margin: 0 !important;
			padding: 10px !important;
		}
	}
}
/* End Shopper Approved css*/
/* Start RightNowWeb chat section*/
#conditionalChatLinkContainer{
	display:none;
	&.rn_ChatAvailable{
		display:block;
	}
}
/* End RightNowWeb chat section*/