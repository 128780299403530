.accordion-item {
	margin-bottom: 15px;
	border-radius: 4px;
}

.accordion-button {
	font-size: 18px;
	border-radius: 4px;
}

.accordion-item:not(:first-of-type) {
	border-top: 1px solid $slate-500;
}


.accordion--list {
	.accordion-button {
		color: #ffffff;
		font-family: $font-family-sans-serif;
		font-size: 22px;
		font-weight: 700;
		border-radius: 4px;
		background-color: $secondary-dark;

		&:after {
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(217deg) brightness(108%) contrast(107%);
		}
		&:not(.collapsed):after {
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(217deg) brightness(108%) contrast(107%);
		}

		&:focus {
			border-color: $secondary-dark;
			box-shadow: 0 0 0 0.25rem rgb(0 5 105 / 25%);
			outline: 0;
			z-index: 3;
		}
	}

	.accordion-button-success {
		background-color: $success;

		&:after {
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(217deg) brightness(108%) contrast(107%);
		}
		&:not(.collapsed):after {
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(217deg) brightness(108%) contrast(107%);
		}

		&:focus {
			border-color: $success;
			box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%);
			outline: 0;
			z-index: 3;
		}
	}
}