.color--red, .color--primary {
	color: var(--vc-primary);
}

.color--blue, .color--secondary {
	color: var(--vc-secondary);
}

.color--slate {
	color: var(--vc-slate);
}

.color--white {
	color: var(--vc-white);
}

.color--black {
	color: var(--vc-black);
}

.color--yellow {
	color: var(--vc-yellow);
}


.reveal {
	opacity: 0;
	&.active {
		opacity: 100%;
	}
}

.w-max-100 {
	max-width: 100%;
}

.cc-1 {
	-webkit-column-count: 1!important;
	-webkit-perspective: 1px;
}

.cc-md-3 {
	@include media-breakpoint-up(md) {
		-webkit-column-count: 3!important;
		-webkit-perspective: 1px;
	}
}

.cc-xl-4 {
	@include media-breakpoint-up(xl) {
		-webkit-column-count: 4!important;
		-webkit-perspective: 1px;
	}
}

.break {
	&-in-avoid {
		break-inside: avoid;
	}
	break-after: column;
}

// Used with functions
.hidden {
	display: none;
}
.showing {
	display: block;
	opacity: 0;
	transition: opacity .5s ease;
}

.shown {
	transition: opacity .5s ease;
}
