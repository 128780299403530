footer {
	position: relative;
	background-color: $gray-200;
}

.footer__top {
	padding: 40px 0;

	.site-logo {
		width: 160px;
		height: auto;
	}

	.certification-wrapper {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		padding-left: 0;

		@include media-breakpoint-up(sm) {
			padding-left: 50px;
			align-items: flex-start;
		}

		p {
			margin-left: 9px;
		}
	}

	.logo-group {
		display: flex;
		margin-bottom: 24px;
		padding-left: 0;
		align-items: baseline;
		flex-direction: column;
		flex-wrap: wrap;
		list-style: none;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}

		a {
			border: none;

			img {
				transform: scale(1);
			}

			&:hover, &:focus {
				border: none;

				img {
					transition: $speed $ease;
					transform: scale(1.05);
				}
			}
		}

		li {
			flex: 1 0 auto;
		}

		> li + li {
			margin-left: 0;
			@include media-breakpoint-up(sm) {
				margin-left: 15px;
			}
			@include media-breakpoint-up(lg) {
				margin-left: 25px;
			}
		}

		.aicpa {
			width: 100px;
			height: 100px;

			@include media-breakpoint-between(sm,lg) {
				width: 65px;
				height: 65px;
			}
		}

		.visa-global {
			width: 100px;
			height: 100px;

			@include media-breakpoint-between(sm,lg) {
				width: 65px;
				height: 65px;
			}
		}

		.truste, .trustelink {
			width: 142px;
			height: auto;
		}

		.hipaa{
			width: 100px;
			height: 100px;

			@include media-breakpoint-between(sm,lg) {
				width: 65px;
				height: 65px;
			}
		}
	}

	.shopper-approved {
		width: 142px;
		height: 60px;
	}

	.trustseal, .trustlink {
		width: 140px;
		height: 80px;
	}
}

.footer__home-link {
	border: none;
	&:hover, &:focus, &:active {
		border: none;
	}
}

.footer__bottom {
	padding: 30px 0 155px;
	background-color: $slate-600;
	color: #ffffff;

	@include media-breakpoint-up(sm) {
		padding: 30px 0;
	}
}

// Footer navigation
.footer-nav--wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include media-breakpoint-up(sm) {
		&:after {
			content: "";
			position: absolute;
			width: 2px;
			height: 100%;
			right: 0;
			background-color: $gray-400;
		}
	}

	@include media-breakpoint-up(lg) {
		justify-content: space-around;
	}
}

.footer-nav {
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: 400;
	vertical-align: top;

	> ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.nav-item {
		padding: 4px 0;
	}

	.nav-link {
		display: inline;
		padding: 0;
		color: #000000;
		font-weight: 400;
		border-bottom-color: transparent;

		&:hover, &:focus, &:active {
			color: var(--vc-link-hover-color);
			border-bottom-color: var(--vc-link-hover-color);
			font-weight: 400;
		}
	}
}

.footer-nav--utility {
	font-size: 12px;
}

.footer-nav--secondary {
	//> ul {
	//	@include zerolist;
	//	display: flex;
	//}
	//
	.nav-item {
		font-size: 14px;

		@media (max-width: 468px) {
			font-size: 16px;
		}

	//	& + .nav-item {
			//margin-left: 4px;
			//&:before {
			//	content: "|";
			//	margin-right: 4px;
			//}
		//}
	}
	//
	//a {
		//color: #FFFFFF;
		//&:hover, &:focus {
		//	border-color: #FFFFFF;
		//	color: #ffffff;
		//}
//	}
}

.copyright {
	font-size: 12px;
	text-align: center;

	@include media-breakpoint-up(lg) {
		text-align: left;
	}

	& .nav-link {
		padding: 0;
		color: #fff;
		display: inline-block;

		&:hover, &:focus {
			border-color: #FFFFFF;
			color: #ffffff;
		}
	}
}