// This is the header for the whole page
#mainNavbar {
	@include media-breakpoint-up(md) {
		flex-direction: column-reverse;
	}
}

.site-header-navcontainer {
	align-items: flex-start;
	margin-left: auto;
	margin-top: 0;
	padding: 8px 0;
	left: -15px;
	width: 100vw;
	z-index: 99;

	@include media-breakpoint-up(md) {
		background: none;
		align-items: center;
		margin-top: 0;
		padding: 18px 0;
		position: static;
		width: auto;
		top: auto;
		left: auto;
	}

	ul {
		font-weight: $font-weight-bold;

		li {
			&.nav-item {
				@include media-breakpoint-up(md) {
					padding: 8px 12px;
				}
				@include media-breakpoint-up(lg) {
					padding: 8px 24px;
				}
			}

			&.nav-item-btn {
				@include media-breakpoint-up(md) {
					padding-right: 0;
				}

				.btn-primary {
					@include media-breakpoint-up(md) {
						padding: 0;
						color: $primary;
						border: 3px solid transparent;
						border-radius: 80px;
						background-image: linear-gradient(white, white),
						radial-gradient(
														circle at top left,
														$primary-light,
														$primary-dark
						);
						background-position: right center;
						background-clip: content-box, border-box;
						background-origin: border-box;
						background-size: 100% 100%;

						span {
							display: inline-block;
							padding: 0.75rem 1.75rem;
							border-radius: 80px;
							background: #fff;
						}

						&:hover {
							color: $primary-dark;
							border-color: transparent;
							background-size: 200% 100%;
						}
					}
				}
			}
		}

		&.site-header-navlist--main {
			font-size: 20px;

			@include media-breakpoint-down(md) {
				position: relative;
				display: block;
				width: 100%;
			}

			@include media-breakpoint-up(md) {
				margin-top: 15px;
				//font-size: 16px;
				//font-weight: 600;
			}

			.nav-link {
				@include media-breakpoint-down(md) {
					font-size: 18px;
				}
			}

			.nav-item {
				@include media-breakpoint-down(md) {
					border-bottom: 1px solid var(--vc-gray-600);
				}
			}
		}

		&.site-header-navlist--utility {
			position: relative;
			top: -8px;
			padding: 0;
			width: 100%;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
				margin-left: auto;
				padding: 0;
				font-size: 12px;
				top: auto;
				width: auto;
			}

			li {
				display: block;
				width: auto;
				border: none;

				@include media-breakpoint-up(md) {
					margin-left: 20px;
				}

				&.nav-item {
					padding: 0;
				}

				// This is for the Espanol button
				.nav-link {
					color: rgba(#fff, 1);
					font-size: 18px;
					padding: 16px 24px;
					border-bottom: none;

					&:hover, &:focus {
						color: #fff;
						border-bottom: none;
						background-color: var(--vc-gray-700);
					}

					@include media-breakpoint-up(md) {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 8px 10px 8px 5px;
						width: 94px;
						background: none;
						font-size: 12px;
						line-height: 13px;
						border-radius: 50px;
						border: 1px solid $gray-500;
						color: $black;
						font-weight: 400;

						&:hover, &:focus {
							color: $black;
							box-shadow: $box-shadow;
							border: 1px solid $gray-500;
							background-color: transparent;
						}
					}

					svg {
						display: none;
						@include media-breakpoint-up(md) {
							display:inline-block;
							font-size: 16px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}

	.dropdown-menu {
		@include media-breakpoint-up(md) {
			svg {
				display: none;
			}
		}
		@include media-breakpoint-down(md) {
			position: relative;
			border-radius: 0;
			border: none;
			top: -2px;

			.dropdown-item {
				display: inline;
				border-bottom: 1px solid $white;
				background-color: var(--vc-primary);
				color: $white;
				padding: 0;
				font-size: 18px;
				font-weight: 400;
			}

			li {
				background-color: var(--vc-primary);
				padding: 5px 5px 5px 55px;
				color: $white;

				&:last-child {
					padding-bottom: 25px;
				}

				&:nth-child(2n) {
					background-color: var(--vc-primary);
				}

				svg {
					color: $white;
					top: 1px;
					position: relative;
				}
			}
		}
	}

	.navbar-collapse {
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 91px;
			background: $black;
			flex-direction: column;
			align-items: flex-start;
			opacity: 0;
			width: 100%;
			min-height: 0;
			transition: opacity $speed $ease;
		}

		@include media-breakpoint-up(md) {
			display: flex !important; // stylelint-disable-line declaration-no-important
			flex-basis: auto;
		}

		&.show {
			display: flex;
			opacity: 1;
			min-height: calc(100vh - 69px);
			transition: opacity $speed $ease;

			@include media-breakpoint-only(sm) {
				display: block;
				width: 300px;
				left: 0;
				min-height: auto;
			}
		}

		.btn-container {
			display: flex;
			align-items: baseline;
			flex-direction: column;
			width: 100%;

			@include media-breakpoint-up(md) {
				flex-direction: row;
				margin-left: auto;
				width: auto;
			}
		}

		.btn-group {
			@include media-breakpoint-down(md) {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
	}
}


.navbar-expand-lg {

	.navbar-toggler {
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	.navbar-nav {
		@include media-breakpoint-up(md) {
			align-items: center;
			flex-direction: row;
		}

		.nav-item {
			@include media-breakpoint-down(md) {
				border-bottom: 1px solid var(--vc-gray-600);
				background-color: transparent;

				&.nav-item-btn {
					margin-top: 25px;
					margin-left: 16px;
				}
			}
		}

		.nav-link {
			color: #fff;
			padding: 16px 24px;
			font-size: 18px;
			border-bottom: none;

			&:hover, &:focus {
				color: #fff;
				border-bottom: none;
				background-color: var(--vc-gray-700);
			}

			@include media-breakpoint-up(md) {
				color: var(--vc-secondary);
				padding: 0; // Set this to 0 so we can use border-bottom for fancy hover border instead of text-decoration: underline;
				border-bottom: 1px solid transparent;
				font-size: var(--vc-nav-link-font-size);
				font-weight: var(--vc-nav-link-font-weight);
				line-height: 13px;
				text-transform: uppercase;

				&:hover, &:focus {
					color: var(--vc-secondary);
					border-bottom: 1px solid var(--vc-secondary);
					background-color: transparent;
					font-weight: var(--vc-nav-link-font-weight);
				}
			}
		}
	}
}

.navbar-toggler {
	position: relative;
	float: right;

	&:hover, &:focus {
		box-shadow: none;

		rect {
			fill: $navbar-light-hover-color;
		}
	}

	rect {
		transition: all $speed $ease;
		transform: rotate(0deg);
		transform-origin: center center;
		fill: $navbar-light-color;

		&.navbar-bars-top {
			transform: translateY(-6px);
		}

		&.navbar-bars-btm {
			transform: translateY(6px);
		}
	}

	&[aria-expanded="true"] {
		rect {
			&.navbar-bars-top {
				transform: rotate(45deg);
			}

			&.navbar-bars-middle {
				transform: translateX(-100%);
				opacity: 0;
			}

			&.navbar-bars-btm {
				transform: rotate(-45deg);
			}
		}
	}
}