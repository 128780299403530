.form-floating.input-group {
	> label {
		z-index: 5;
	}
	.btn {
		padding-left: 10px;
		padding-right: 10px;
	}
	a.btn {
		padding-top: 16px;
		line-height: 1.4;
	}
}