:root {
	--#{$variable-prefix}red-dark: #{inspect($red-dark)};
	--#{$variable-prefix}red-med: #{inspect($red-med)};
	--#{$variable-prefix}red-light: #{inspect($red-light)};
	--#{$variable-prefix}blue-dark: #{inspect($blue-dark)};
	--#{$variable-prefix}blue-med: #{inspect($blue-med)};
	--#{$variable-prefix}blue-light: #{inspect($blue-light)};
	--#{$variable-prefix}blue-050: #{inspect($blue-050)};
	--#{$variable-prefix}blue-100: #{inspect($blue-100)};
	--#{$variable-prefix}blue-200: #{inspect($blue-200)};
	--#{$variable-prefix}blue-300: #{inspect($blue-300)};
	--#{$variable-prefix}blue-400: #{inspect($blue-400)};
	--#{$variable-prefix}blue-500: #{inspect($blue-500)};
	--#{$variable-prefix}blue-600: #{inspect($blue-600)};
	--#{$variable-prefix}blue-700: #{inspect($blue-700)};
	--#{$variable-prefix}blue-800: #{inspect($blue-800)};
	--#{$variable-prefix}blue-900: #{inspect($blue-900)};
	--#{$variable-prefix}red-050: #{inspect($red-050)};
	--#{$variable-prefix}red-100: #{inspect($red-100)};
	--#{$variable-prefix}red-200: #{inspect($red-200)};
	--#{$variable-prefix}red-300: #{inspect($red-300)};
	--#{$variable-prefix}red-400: #{inspect($red-400)};
	--#{$variable-prefix}red-500: #{inspect($red-500)};
	--#{$variable-prefix}red-600: #{inspect($red-600)};
	--#{$variable-prefix}red-700: #{inspect($red-700)};
	--#{$variable-prefix}red-800: #{inspect($red-800)};
	--#{$variable-prefix}red-900: #{inspect($red-900)};
	--#{$variable-prefix}green-100: #{inspect($green-100)};
	--#{$variable-prefix}green-200: #{inspect($green-200)};
	--#{$variable-prefix}green-300: #{inspect($green-300)};
	--#{$variable-prefix}green-400: #{inspect($green-400)};
	--#{$variable-prefix}green-500: #{inspect($green-500)};
	--#{$variable-prefix}green-600: #{inspect($green-600)};
	--#{$variable-prefix}green-700: #{inspect($green-700)};
	--#{$variable-prefix}green-800: #{inspect($green-800)};
	--#{$variable-prefix}green-900: #{inspect($green-900)};
	--#{$variable-prefix}yellow-100: #{inspect($yellow-100)};
	--#{$variable-prefix}yellow-200: #{inspect($yellow-200)};
	--#{$variable-prefix}yellow-300: #{inspect($yellow-300)};
	--#{$variable-prefix}yellow-400: #{inspect($yellow-400)};
	--#{$variable-prefix}yellow-500: #{inspect($yellow-500)};
	--#{$variable-prefix}yellow-600: #{inspect($yellow-600)};
	--#{$variable-prefix}yellow-700: #{inspect($yellow-700)};
	--#{$variable-prefix}yellow-800: #{inspect($yellow-800)};
	--#{$variable-prefix}yellow-900: #{inspect($yellow-900)};
	--#{$variable-prefix}slate: #{inspect($slate)};
	--#{$variable-prefix}slate-dark: #{inspect($slate-dark)};
	--#{$variable-prefix}slate-light: #{inspect($slate-light)};
	--#{$variable-prefix}font-serif: #{inspect($font-family-serif)};
	--fa-primary-color: var(--vc-gray-600);
	--fa-secondary-color: var(--vc-gray-400);
	--fa-secondary-opacity: 1.0;
}