.btn {
	line-height: normal;
	padding: 7px 5px;
	font-size: 12px;

	@include media-breakpoint-up(sm) {
		padding: 7px 15px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 14px;
	}
}

.btn-close {
	border: 1px solid var(--vc-black);
	border-radius: 50%;
	background: transparent var(--vc-btn-close-bg) center/.55em auto no-repeat;

	@include media-breakpoint-up(sm) {
		width: 1em;
		height: 1em;
		background: transparent var(--vc-btn-close-bg) center/.75em auto no-repeat;
	}
}

.btn-close--modal {
	width: 12px;
	height: 12px;
	// override padding from bootstrap
	padding: calc(var(--#{$prefix}modal-header-padding-y) * .3) calc(var(--#{$prefix}modal-header-padding-x) * .3) !important;
}

.btn-icon {
	display: flex;
	align-items: center;
	justify-content: space-between;
	svg {
		padding-left: 5px;
		height: 14px;

		@include media-breakpoint-up(sm) {
			padding-left: 10px;
			height: 18px;
		}
	}
}

.btn-container {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	--vc-gutter-y: 16px;

	@include media-breakpoint-up(sm) {
		flex-direction: row;
		--vc-gutter-y: 0;
	}

	.btn-group {
		@include media-breakpoint-up(sm) {
			margin-top: var(--vc-gutter-y);
		}
	}
}

.btn-group--spaced {
	@include media-breakpoint-down(sm) {
		//display: flex;
		//flex-direction: column;
	}

	> :not(:last-child)>.btn,
	>.btn:not(:last-child) {
		border-radius: var(--vc-border-radius) !important;
	}

	> :not(:first-child)>.btn,
	>.btn:nth-child(n+3),
	> :not(.btn-check)+.btn {
		border-radius: var(--vc-border-radius) !important;
	}

	//> :not(.btn-check:first-child) + .btn,
	//> :not(:first-child) {
		//margin-left: 0;
		//margin-top: 10px;
		//
		//@include media-breakpoint-up(sm) {
		//	margin-left: 10px;
		//	margin-top: 0;
		//}

	//}
}

.btn-primary {
	color: #fff;
	box-shadow: none;
	transition: all 0.5s;

	&:hover, &:focus {
		@extend %vcgradient-primary;
		background-position: right center;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.4);
		//transition: all 0.5s;
	}
}

.btn-secondary {
	background-color: var(--vc-white);
	border-color: var(--vc-secondary);
	color: var(--vc-secondary);
	box-shadow: none;
	transition: all 0.5s;

	&.show {
		background-color: var(--vc-white);
		border-color: var(--vc-secondary);
		color: var(--vc-secondary);
	}

	&:hover, &:focus {
		@extend %vcgradient-secondary;
		background-color: var(--vc-btn-hover-bg);
		border-color: var(--vc-btn-hover-border-color);
		color: var(--vc-white);
		background-position: right center;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.4);
		transition: all 0.5s;
	}
}

.btn--accordion {
	width: 100%;
	padding: 0;
	text-align: left;
	border: 0;
	background-color: transparent;
	white-space: normal;
	display: flex;
	justify-content: space-between;

	&:hover, &:focus, &:active {
		color: $red;
	}

}

.btn--hero {

	@include media-breakpoint-up(md) {
		font-size: 20px;
		padding: 16px 32px;
	}

	svg {
		margin-right: 10px;
	}
}

.btn-link {
	border: none; // reset
	border-bottom: 1px solid transparent;
	background-color: transparent;
	color: var(--vc-secondary);
	font-weight: 700;
	transition: all $ease $speed;

	&:hover, &:focus, &:active {
		border-bottom-color: var(--vc-primary);
	}
}

.btn--nav-dropdown {
	@include media-breakpoint-down(md) {
		font-size: 18px;
		text-align: left;
		line-height: normal;
		background: var(--vc-primary);
		color: $white;
		border: none;
		border-radius: 0;
		padding: 25px;

		&:hover, &:focus, &:active, &:active:focus {
			box-shadow: none;
			background-color: var(--vc-primary);
			background-image: none;
		}

		&.show {
			&:hover, &:focus, &:active, &:active:focus {
				box-shadow: none;
				background-image: none;
			}
		}
	}

	@include media-breakpoint-up(md) {
		font-size: 10px;
		line-height: normal;
		background: $white;
		color: $secondary;
	}
}

.btn--hero-dropdown, .btn-secondary--hero {
	font-size: 10px;

	@include media-breakpoint-up(lg) {
		font-size: 14px;
	}

}

.btn-secondary--hero {
	color: $secondary;
	background-image: none;
	background-color: $white;
	box-shadow: none;
	transition: all 0.5s;

	&:hover, &:focus {
		@extend %vcgradient-secondary;
		background-position: right center;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.4);
		//transition: all 0.5s;
	}
}

//#chooseFile, #takePhoto {
//	flex: 2 0 0;
//}

.btn--choose-icon {
	height: 1.125rem;
	padding-left: 0.625rem
}

.btn-group--choose {
	//d-flex align-items-center d-lg-block
	display: flex;
	align-items: center;

	@include media-breakpoint-up(lg) {
		display: block;
	}
}

// Button Web Components (normally the styles would go into the component class
button-choose {
	display: block;
	opacity: 1;
	//opacity: 0;
	//transition: opacity .3s ease-in-out;
	//&.show {
	//	opacity: 1;
	//	transition: opacity .3s ease-in-out;
	//}

	&:not(:last-child) {
		.button-group--choose {
			padding-bottom: 25px;
		}
	}

	.button-group--choose {
		display: flex;
		align-items: center;
	}

	button {
		width: 100%;
		flex: 2 0;
		@include media-breakpoint-up(lg) {
			width: auto;
			flex: none;
		}
	}

	.label--button-choose {
		display: none;
		&.show {
			display: block;
		}
	}
}

button-additional-file {
	flex: 1 1 auto;
	position: relative;
	width: 100%;
}
