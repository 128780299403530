// Table using divs/columns
.cols-table {

}

.cols-table--white {
	background-color: $white;
}

.row-th {
	border: 1px solid $slate-200;

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.row-td {
	border: 1px solid $slate-200;
	border-top: none;
	//@include media-breakpoint-down(md) {
	//	border-top: 1px solid $slate-200;
	//	border-bottom: 1px solid $slate-200;
	//}

	&+ .row-td {
		@include media-breakpoint-down(md) {
			margin-top: 20px;
		}
	}
}

.col-th {
	padding: 10px;
	font-size: 14px;
	font-weight: 700;
	background-color: $slate-050;

	+ .col-th {
		border-left: 1px solid $slate-200;
	}
}

.col-td {
	display: flex;
	padding: 10px;
	font-size: 14px;
	font-weight: 400;
	border-top: 1px solid $slate-200;
	border-bottom: none;

	&:first-child {
		@include media-breakpoint-up(md) {
			border-top: none;
		}
	}

	//&:last-child {
	//	@include media-breakpoint-up(md) {
	//		border-bottom: 1px solid $slate-200;
	//	}
	//}

	@include media-breakpoint-up(md) {
		border-top: none;
		border-bottom: none;
	}

	+ .col-td {
		border-left: 1px solid $slate-200;
		@include media-breakpoint-down(md) {
			border-left: none;
		}
	}
}
