#universalModal {
	img {
		max-width: 100%;
	}
}

.modal {
	font-family: $font-family-sans-serif;
}

.modal-header {
	background-color: var(--vc-gray-200);
}

.modal-title {
	font-weight: 700;
	font-size: 14px;
	margin: 0;
	color: var(--vc-secondary);

	@include media-breakpoint-up(md) {
		font-size: 16px;
	}
}

.modal-footer {
	border-top: none;
}