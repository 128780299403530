.hero-new {
	position: relative;
	display: block;
	background: $gray-100;
	z-index: 2;

	@include media-breakpoint-up(lg) {
		align-items: inherit;
		max-height: 450px;
	}

	.dropdown-item {
		padding: var(--vc-dropdown-item-padding-y) 5px;

		@include media-breakpoint-up(lg) {
			padding: var(--vc-dropdown-item-padding-y) var(--vc-dropdown-item-padding-x)
		}
	}
}

.hero-new__content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 50px;

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
	@include media-breakpoint-up(md) {
		align-items: inherit;
		padding-top: 0;
	}
}

.hero-new__text-container {
	text-align: center;
	max-width: 450px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@include media-breakpoint-between(sm, lg) {
		align-items: center;
	}

	@include media-breakpoint-up(lg) {
		width: 595px;
		min-width: 522px;
		max-width: none;
	}
}

.hero-new__text {
	@include media-breakpoint-up(sm) {
		position: relative;
		padding: 0;
		z-index: 1;
	}

	h1 {
		font-family: $font-family-sans-serif;
		@include font-size(24px);
		line-height: 30px;

		@include media-breakpoint-between(sm, lg) {
			margin-bottom: 5px;
			@include font-size(32px);
			line-height: 32px;
		}

		@include media-breakpoint-between(lg, xl) {
			@include font-size(26px);
			line-height: 32px;
		}

		@include media-breakpoint-between(lg, xxxl) {
			@include font-size(33px);
			line-height: 41px;
		}

		@include media-breakpoint-up(xxl) {
			@include font-size(36px);
			line-height: 44px;
		}
	}

	p {
		font-size: 14px;
		line-height: 26px;

		@include media-breakpoint-between(sm, lg) {
			@include font-size(16px);
			line-height: 34px;
			margin-bottom: 20px;
		}
		@include media-breakpoint-up(lg) {
			@include font-size(22px);
			line-height: 36px;
			margin-bottom: 20px;
		}
	}

	.btn-group {
		>.btn-group {
			&:not(:first-child) {
				>.btn {
					border-bottom-left-radius: 3px;
					border-top-left-radius: 3px;
				}
			}

			&:not(:last-child) {
				>.btn {
					border-bottom-right-radius: 3px;
					border-top-right-radius: 3px;
				}
			}
		}

		>.btn.dropdown-toggle-split {
			&:first-child {
				border-bottom-right-radius: 3px;
				border-top-right-radius: 3px;
			}
		}

		>.btn {
			&:not(:last-child) {
				&:not(.dropdown-toggle) {
					border-bottom-right-radius: 3px;
					border-top-right-radius: 3px;
				}
			}
			&:nth-child(n+3) {
				border-bottom-left-radius: 3px;
				border-top-left-radius: 3px;
			}
		}
	}
}

.hero-new__img-container {
	position: relative;
	display: flex;
	align-items: flex-end;
	height: 100%;
	@include padding-top(20px);

	@include media-breakpoint-up(lg) {

	}

	img {
		max-height: 421px;
		max-width: 100%;
	}
}

.hero-new__title {
	font-size: 36px;
	line-height: 44px;
	font-weight: 700;
	color: var(--vc-secondary);

	@include media-breakpoint-between(sm, lg) {
		font-size: 26px;
		line-height: 32px;
	}
}

.hero-new__text {
	font-size: 24px;
	line-height: 36px;
	font-weight: 400;
	color: var(--vc-black);

	@include media-breakpoint-between(sm, lg) {
		font-size: 18px;
		line-height: 24px;
	}
}


.hero {
	position: relative;
	display: block;
	background: $gray-100;
	z-index: 2;

	//@include media-breakpoint-down(lg) {
	//	min-height: 450px;
	//}

	@include media-breakpoint-up(lg) {
		border-top: 1px solid $slate-500;
		border-bottom: 1px solid $slate-500;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;

		&:after {
			background: linear-gradient(35deg, $slate-500 0%, $slate-200 30%, transparent 60%, transparent 100%);
			//filter: blur(100px);
			opacity: 80%;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
		}
	}
	.bgphoto {
		display: block;
		height: auto;
		width: 100%;
		z-index: 2;

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	.container {
		position: relative;
		z-index: 2;
	}
}

.hero__text-container {
	font-size: 18px;
	padding: 62px 0;

	@include media-breakpoint-up(lg) {
		padding: 120px 0 75px;
		max-width: 590px;
		display: flex;
		/* align-content: baseline; */
		flex-direction: column;
		justify-content: center;
		line-height: 1.8889;
	}

	@include media-breakpoint-between(lg, xl) {
		min-height: 600px;
	}

	@include media-breakpoint-between(xl, xxxl) {
		min-height: 750px;
	}

	@include media-breakpoint-up(xxxl) {
		min-height: 1000px;
	}
}

.hero__text {
	@include media-breakpoint-up(sm) {
		position: relative;
		padding: 36px;
		border-radius: 4px;
		border: 1px solid $slate-200;
		box-shadow: $box-shadow;
		overflow: hidden;
		z-index: 1;

		&:after {
			background: $white;
			filter: blur(50px);
			opacity: .9;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -1;
		}
	}

	h2 {
		font-family: $font-family-sans-serif;
		font-size: 22px;
		line-height: 28px;
		font-weight: 700;
		@include media-breakpoint-up(lg) {
			font-size: 26px;
			line-height: 32px;
		}
	}

	p {
		@include media-breakpoint-up(lg) {
			margin-bottom: $grid-gutter-width * 2;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.fa-certificate {
		font-size: 48px;
		vertical-align: middle;
	}
}

.hero--accessibility {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-accessibility-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-accessibility-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-accessibility-scale-w1440.jpg");
	}
}

.hero--birth {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-birth-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-birth-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-birth-scale-w1440.jpg");
	}

}

.hero--bornabroad, .hero--international-records {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-international-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-international-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-international-scale-w1440.jpg");
	}
}

.hero--customer-testimonials {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-testimonials-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-testimonials-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-testimonials-scale-w1440.jpg");
	}
}

.hero--death {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-death-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-death-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-death-scale-w1440.jpg");
	}
}

.hero--divorce {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-divorce-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-divorce-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-divorce-scale-w1440.jpg");
	}
}

.hero--faqs {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-faqs-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-faqs-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-faqs-scale-w1440.jpg");
	}
}

.hero--funeral-home {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-funeral-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-funeral-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-funeral-scale-w1440.jpg");
	}
}

.hero--home {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/home-hero-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/home-hero-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/home-hero-scale-w1440.jpg");
	}

	.fa-certificate {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.hero--marriage {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-marriage-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-marriage-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-marriage-scale-w1440.jpg");
	}
}

.hero--identogo {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-identogo-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-identogo-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-identogo-scale-w1440.jpg");
	}
}

.hero--privacy {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-privacy-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-privacy-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-privacy-scale-w1440.jpg");
	}
}

.hero--security {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-security-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-security-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-security-scale-w1440.jpg");
	}
}

.hero--ups {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-shipping-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-shipping-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-shipping-scale-w1440.jpg");
	}
}

.hero--realid {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-realid-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-realid-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-realid-scale-w1440.jpg");
	}
}

.hero--site-map {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-couple-tablet-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-couple-tablet-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-couple-tablet-scale-w1440.jpg");
	}
}

.hero--support {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-support-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-support-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-support-scale-w1440.jpg");
	}
}

.hero--timing {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-timing-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-timing-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-timing-scale-w1440.jpg");
	}
}

.hero--vital-records {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/hero-vital-records-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/hero-vital-records-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/hero-vital-records-scale-w1440.jpg");
	}
}

.hero--why {
	@include media-breakpoint-between(lg,xl) {
		background-image: url("../../images/atomic/headers/why-hero-scale-w1352.jpg");
	}
	@include media-breakpoint-between(xl, xxl) {
		background-image: url("../../images/atomic/headers/why-hero-scale-w1440.jpg");
	}
	@include media-breakpoint-up(xxl) {
		background-image: url("../../images/atomic/headers/why-hero-scale-w1440.jpg");
	}
}
