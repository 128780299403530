blockquote {
	font-size: 20px;
	font-style: italic;
	line-height: 28px;
	border-left: 5px solid $slate-600;
	border-radius: 4px;
	padding: 20px 20px 20px 30px;
}

.testimonial {
	margin-bottom: 35px;
}

.testimonial__author {
	margin-top: 20px;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 700;

	&:before {
		content: "\2014 \20";
	}
}