/*
@file: _colors.scss
@desc: Overrides specific Bootstrap 5 color variables. Adds new color variables specific to VitalChek.com
@author: Brian Wilkins
*/

// Colors

// scss-docs-start gray-color-variables
$white:    #ffffff;
$gray-100: #F4F6F9;
$gray-200: #EAEAEA;
$gray-300: #dadada;
$gray-400: #cccccc;
$gray-500: #999999;
$gray-600: #7C7D7E;
$gray-700: #444444;
$gray-800: #333333;
$gray-900: #222222;
$black:    #000000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
				"100": $gray-100,
				"200": $gray-200,
				"300": $gray-300,
				"400": $gray-400,
				"500": $gray-500,
				"600": $gray-600,
				"700": $gray-700,
				"800": $gray-800,
				"900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: 		#1B2A98;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red: 		#C31414;
$orange: 	#c13e14;
$yellow:  #FFB500;
$green:   #539a18;
$teal:    #20c997;
$cyan:    #0dcaf0;
$slate:		#c7d2e2;
// scss-docs-end color-variables

$orange-light: 	#ff9d00;
$orange-med: 		#f3511e;
$orange-dark: 	#a72e0a;

$black: 				#000000;

// scss-docs-start colors-map
$colors: (
				"blue":       $blue,
				"indigo":     $indigo,
				"purple":     $purple,
				"pink":       $pink,
				"red":        $red,
				"orange":     $orange,
				"yellow":     $yellow,
				"green":      $green,
				"teal":       $teal,
				"cyan":       $cyan,
				"black":      $black,
				"white":      $white,
				"gray":       $gray-600,
				"gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;

// fusv-disable
$blue-050:			#e7e9f7;
$blue-100:			#c3c6ea;
$blue-200:			#9ba2dc;
$blue-300:			#737ecf;
$blue-400:			#5462c5;
$blue-500:			#3446ba;
$blue-600:			#2e3eb0;
$blue-700:			#2534a4;
$blue-800:			$blue;
$blue-900:			#051684;

$blue-dark: 		#000569;
$blue-med: 			$blue-600;
$blue-light: 		$blue-300;

$red-050:				#ffebee;
$red-100:				#ffced2;
$red-200:				#f79b99;
$red-300:				#ef7471;
$red-400:				#fa534c;
$red-500:				#fe412f;
$red-600:				#f1382f;
$red-700:				#de2c2a;
$red-800:				#d22422;
$red-900:				$red;

$red-light: 		$red-400;
$red-med: 			$red-700;
$red-dark: 			#830606;

$slate-050:			#e8ecf9;
$slate-100:			$slate;
$slate-200:			#a7b3c7;
$slate-300:			#8795ae;
$slate-400:			#6f7f9a;
$slate-500:			#586a87;
$slate-600:			#4a5d77;
$slate-700:			#3b4a61;
$slate-800:			#2c384b;
$slate-900:			#1a2434;

$slate-light: 	$slate-500;
$slate-muted: 	$slate-200;
$slate-dark: 		$slate-900;

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: #feedb4;
$yellow-200: #fee283;
$yellow-300: #fed850;
$yellow-400: #ffcd29;
$yellow-500: #ffc509;
$yellow-600: $yellow;
$yellow-700: #ffa400;
$yellow-800: #ff9300;
$yellow-900: #ff7300;

$green-100: #d7ebc1;
$green-200: #bcde99;
$green-300: #a1d16f;
$green-400: #8bc74e;
$green-500: #76bd2a;
$green-600: #67ae23;
$green-700: $green;
$green-800: #3d860d;
$green-900: #0d6500;

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);


$blues: (
				"blue-050": $blue-050,
				"blue-100": $blue-100,
				"blue-200": $blue-200,
				"blue-300": $blue-300,
				"blue-400": $blue-400,
				"blue-500": $blue-500,
				"blue-600": $blue-600,
				"blue-700": $blue-700,
				"blue-800": $blue-800,
				"blue-900": $blue-900
);

$reds: (
				"red-050": $red-050,
				"red-100": $red-100,
				"red-200": $red-200,
				"red-300": $red-300,
				"red-400": $red-400,
				"red-500": $red-500,
				"red-600": $red-600,
				"red-700": $red-700,
				"red-800": $red-800,
				"red-900": $red-900
);

$indigos: (
				"indigo-100": $indigo-100,
				"indigo-200": $indigo-200,
				"indigo-300": $indigo-300,
				"indigo-400": $indigo-400,
				"indigo-500": $indigo-500,
				"indigo-600": $indigo-600,
				"indigo-700": $indigo-700,
				"indigo-800": $indigo-800,
				"indigo-900": $indigo-900
);

$purples: (
				"purple-100": $purple-100,
				"purple-200": $purple-200,
				"purple-300": $purple-300,
				"purple-400": $purple-400,
				"purple-500": $purple-500,
				"purple-600": $purple-600,
				"purple-700": $purple-700,
				"purple-800": $purple-800,
				"purple-900": $purple-900
);

$pinks: (
				"pink-100": $pink-100,
				"pink-200": $pink-200,
				"pink-300": $pink-300,
				"pink-400": $pink-400,
				"pink-500": $pink-500,
				"pink-600": $pink-600,
				"pink-700": $pink-700,
				"pink-800": $pink-800,
				"pink-900": $pink-900
);

$oranges: (
				"orange-100": $orange-100,
				"orange-200": $orange-200,
				"orange-300": $orange-300,
				"orange-400": $orange-400,
				"orange-500": $orange-500,
				"orange-600": $orange-600,
				"orange-700": $orange-700,
				"orange-800": $orange-800,
				"orange-900": $orange-900
);

$yellows: (
				"yellow-100": $yellow-100,
				"yellow-200": $yellow-200,
				"yellow-300": $yellow-300,
				"yellow-400": $yellow-400,
				"yellow-500": $yellow-500,
				"yellow-600": $yellow-600,
				"yellow-700": $yellow-700,
				"yellow-800": $yellow-800,
				"yellow-900": $yellow-900
);

$greens: (
				"green-100": $green-100,
				"green-200": $green-200,
				"green-300": $green-300,
				"green-400": $green-400,
				"green-500": $green-500,
				"green-600": $green-600,
				"green-700": $green-700,
				"green-800": $green-800,
				"green-900": $green-900
);

$teals: (
				"teal-100": $teal-100,
				"teal-200": $teal-200,
				"teal-300": $teal-300,
				"teal-400": $teal-400,
				"teal-500": $teal-500,
				"teal-600": $teal-600,
				"teal-700": $teal-700,
				"teal-800": $teal-800,
				"teal-900": $teal-900
);

$cyans: (
				"cyan-100": $cyan-100,
				"cyan-200": $cyan-200,
				"cyan-300": $cyan-300,
				"cyan-400": $cyan-400,
				"cyan-500": $cyan-500,
				"cyan-600": $cyan-600,
				"cyan-700": $cyan-700,
				"cyan-800": $cyan-800,
				"cyan-900": $cyan-900
);
// fusv-enable

$primary: 				$red;
$primary-light: 	$red-light;
$primary-dark: 		$red-dark;

$secondary: 			$blue;
$secondary-light: $blue-light;
$secondary-dark: 	$blue-dark;

$success: 				$green;
$info:						$blue;
$warning:       	$yellow;
$danger:        	$red;
$light:    				$slate-050;
$dark:          	$slate-900;

// scss-docs-start theme-colors-map
$theme-colors: (
				"primary":    $primary,
				"secondary":  $secondary,
				"success":    $success,
				"info":       $info,
				"warning":    $warning,
				"danger":     $danger,
				"light":      $light,
				"dark":       $dark,
				"slate":			$slate,
				"slate-050":	$slate-050,
				"slate-500":	$slate-500,
				"slate-600":	$slate-600
);
// scss-docs-end theme-colors-map

// scss-docs-start theme-text-variables
$primary-text-emphasis:   shade-color($primary, 60%) !default;
$secondary-text-emphasis: shade-color($secondary, 60%) !default;
$success-text-emphasis:   shade-color($success, 60%) !default;
$info-text-emphasis:      shade-color($info, 60%) !default;
$warning-text-emphasis:   shade-color($warning, 60%) !default;
$danger-text-emphasis:    shade-color($danger, 60%) !default;
$light-text-emphasis:     $gray-700 !default;
$dark-text-emphasis:      $gray-700 !default;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle:       tint-color($primary, 80%) !default;
$secondary-bg-subtle:     tint-color($secondary, 80%) !default;
$success-bg-subtle:       tint-color($success, 80%) !default;
$info-bg-subtle:          tint-color($info, 80%) !default;
$warning-bg-subtle:       tint-color($warning, 80%) !default;
$danger-bg-subtle:        tint-color($danger, 80%) !default;
$light-bg-subtle:         mix($gray-100, $white) !default;
$dark-bg-subtle:          $gray-400 !default;
// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle:   tint-color($primary, 60%) !default;
$secondary-border-subtle: tint-color($secondary, 60%) !default;
$success-border-subtle:   tint-color($success, 60%) !default;
$info-border-subtle:      tint-color($info, 60%) !default;
$warning-border-subtle:   tint-color($warning, 60%) !default;
$danger-border-subtle:    tint-color($danger, 60%) !default;
$light-border-subtle:     $gray-200 !default;
$dark-border-subtle:      $gray-500 !default;
// scss-docs-end theme-border-subtle-variables


.bg--white {
	background-color: #fff!important;
}

// Gradients
.gradients {
	position: absolute;
	width: 0;
	height: 0;
	bottom: 0;
}

%vcgradient-primary {
	background-size: 140% auto;
	background-image: linear-gradient(
											114deg,
											$primary-dark 0%,
											$primary 50%,
											$primary-light 100%);
}

%vcgradient-secondary {
	background-size: 140% auto;
	background-image: linear-gradient(
									114deg,
									$secondary-dark 0%,
									$secondary 50%,
									$secondary-light 100%);
}

.bg-gradient-primary {
	background-color: $primary;
	background-image: linear-gradient(
									114deg,
									$primary-dark 0%,
									$primary 50%,
									$primary-light 100%);
}

.bg-gradient-secondary {
		background-color: $secondary;
		background-image: linear-gradient(
										114deg,
										$secondary-dark 0%,
										$secondary 50%,
										$secondary-light 100%);
}

.bg-gradient-slate--after {
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to right, $slate-200 0%, $slate-050 40%, transparent 70%, transparent 100%);
		z-index: -1;
	}
}

.bg-gradient-primary--after {
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to right, $primary-dark 0%, $red 40%, transparent 70%, transparent 100%);
		z-index: -1;
	}
}

.bg-gradient-white--after {
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to right, #ffffff 0%, #ffffff 40%, transparent 70%, transparent 100%);
		z-index: -1;
	}
}

.bg-gradient-white--right--after {
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to left, #ffffff 0%, #ffffff 50%, transparent 70%, transparent 100%);
		z-index: -1;
	}
}
