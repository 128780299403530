
@-webkit-keyframes cameraFlash {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes cameraFlash {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes f {
	from {
		--f: 0;
	}
}
.rectangle {
	width: 200px;
	height: 200px;
	outline: 5px dashed red;
	position: relative;
}
.outer-container {
	border: 1px dotted black;
	width: 100%;
	height: 100%;
	text-align: center;
}
.inner-container {
	border: 1px solid black;
	display: inline-block;
	position: relative;
}
.video-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	margin: 10px;
	padding: 5px 5px;
	font-size: 20px;
	font-family: Helvetica, sans-serif;
	color: #FFF;
	background-color:  rgba(50, 50, 50, 0.3);
}
#videoBox {
	position: relative;
	overflow: hidden;
}
#videoOverlays {
	position: absolute;
	outline: 2px dashed white;
	z-index: 2;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
}
#camera {
	position: absolute;
	height: 95%;
	width: 95%;
	object-fit: scale-down;
}
#cameraSensor {
	position: absolute;
	height: 95%;
	width: 95%;
	object-fit: scale-down;
}
canvas {
	max-width: 100%;
	position: absolute;
	object-fit: scale-down;
}
#videoBoxSpinner {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
}
#cameraView {
	position: relative;
	max-width: 100%;
}
#cameraOutput {
	max-width: 100%;
}
.taken {
	border: solid 3px white;
	box-shadow: 0 0.1875rem 0.25rem 0 rgb(12 32 46 / 20%), 0 0.0625rem 0.375rem rgb(12 32 46 / 40%);
}
.camera-flash {
	background-color: #ffffff;
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 2000;
}
.animate__cameraFlash {
	animation: f 0.05s ease-in-out .1s 1 normal both;
	-webkit-animation-name: cameraFlash;
	animation-name: cameraFlash;
}
