.logo__container {
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	margin-bottom: 50px;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
	}

	.visually-hidden {
		border: none;
		&:hover, &:focus, &:active {
			border: none;
		}
	}
}

.logo--agency {
	&:not(:last-child) {
		//margin-right: 60px;
		@include rfs(30px, margin-right);

		@include media-breakpoint-up(xl) {
			@include rfs(60px, margin-right);
		}

		@include media-breakpoint-between(lg, xl) {
			@include rfs(50px, margin-right);
		}
	}

	&-fldoh, &-iahhs, &-tndoh {
		height: 45px;
		width: auto;
		@include media-breakpoint-between(md, lg) {
			height: 50px;
		}
		@include media-breakpoint-up(lg) {
			height: 60px;
		}
	}

	&-iahhs {
		@media screen and (max-width: 405px) {
			display: none;
		}
	}

	&-oksdoh {
		height: 40px;
		width: auto;
		display: none;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}

		@include media-breakpoint-between(sm, lg) {
			height: 45px;
		}
		@include media-breakpoint-up(lg) {
			height: 50px;
		}
	}

	&-wasdoh {
		height: 35px;
		width: auto;
		display:none;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}

		@include media-breakpoint-between(sm, lg) {
			height: 45px;
		}
		@include media-breakpoint-up(lg) {
			height: 55px;
		}
	}
	&-100s {
		height: 40px;
		width: auto;

		@include media-breakpoint-up(lg) {
			@include rfs(50px, height);
		}
	}
}

.logo--fastport {
	width: 166px;
	height: 44px;
}

.logo--identogo {
	width: 200px;
	height: auto;
}

.logo__link {
	transform: scale(1);
	transition: transform $speed $ease;
	border-bottom: none;

	@include media-breakpoint-down(lg) {
		margin-bottom: 50px;
	}

	&:hover, &:focus, &:active {
		transform: scale(1.05);
		transition: transform $speed $ease;
		border-bottom: none;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.logo--truste {
		width: 142px;
		height: 45px;
		transform: scale(1);
		transition: transform $speed $ease;

		&:hover, &:focus, &:active {
			transform: scale(1.05);
			transition: transform $speed $ease;
			border-bottom: none;
		}
	}
}