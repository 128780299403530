.order-summary__item, .order-summary__total {
	display: flex;
	margin-bottom: 8px;
}

.order-summary__prices {
	margin-bottom: 16px;
}

.order-summary__title {
	margin-bottom: 18px;
	font-family: $font-family-sans-serif;
	font-size: 20px;
	font-weight: 700;
}

.order-summary__total {
	font-weight: 700;
}

.order-summary__label, .order-summary__total-label {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:after {
		content: "..................................................................................................................................................................................................................";
		padding-left: 5px;
	}
}

.order-summary__price, .order-summary__total-price {
	padding-left: 5px;
}
