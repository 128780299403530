.dropdown-toggle {
	&:after {
		margin-left: 10px;
		vertical-align: 2px;

		@include media-breakpoint-up(lg) {
			margin-left: 25px;
		}
	}
}

.dropdown-menu {
	li {
		&:nth-child(even) {
			background-color: $gray-100;
		}
	}
}

.dropdown-item {
	&:hover, &:focus, &:active {
		border-bottom: none;
		font-weight: 400;
	}

	@include media-breakpoint-down(md) {
		padding-left: 5px;
		padding-right: 5px;
	}
}