.alert {
	font-weight: 700;

	.btn-close {
		position: absolute;
		right: 10px;
		top: 15px;
		background-color: var(--vc-white);
	}
}

.alert__message {
	margin-right: 30px;
}

.alert--note {
	border-color: var(--vc-blue-050);
	color: var(--vc-black);
	background-color: var(--vc-blue-050);
	font-weight: normal;
	font-style: italic;
}

.alert-danger {
	border-color: var(--vc-danger);
	color: var(--vc-white);
	background-color: var(--vc-danger);
}

.alert-info {
	border-color: var(--vc-secondary);
	color: var(--vc-white);
	background-color: var(--vc-secondary);
}

.alert-info__title {
	margin-bottom: 8px;
	padding: 4px 0;
	background-color: var(--vc-secondary);
	color: var(--vc-white);
	font-weight: 700;
	text-align: center;
	border-radius: 3px;
}

.alert-success {
	border-color: var(--vc-success);
	color: var(--vc-black);
	background-color: var(--vc-success);
}

.alert-warning {
	border-color: var(--vc-warning);
	color: $black;
	background-color: var(--vc-warning);
}

.alert-white {
	border-color: $slate-500;
	color: $slate;
	background-color: #fff;
}
#alertBanner{
	margin-bottom:0;
	border-radius: 0;
	border-bottom:none;
}