.photo--check {
	display: block;
	margin: 0 auto 30px;
	width: 100px;

	@include media-breakpoint-up(lg) {
		width: 380px;
		height: 380px;
	}
	@include media-breakpoint-up(xl) {
		width: 400px;
		height: 400px;
	}
}

.thumbnail__container {
	position: relative;
	width: 75px;
	height: 75px;
	overflow: hidden;
	border: 1px solid $slate-500;
	border-radius: 4px;
}

.thumbnail {
	width: 100px;
	height: auto;
}

// Refresh
.refresh {
	.thumbnail__container {
		position: relative;
		width: 50px;
		height: 50px;
		overflow: hidden;
		border: 1px solid $gray-500;
		border-radius: var(--vc-border-radius);
	}
}