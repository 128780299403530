h1, .h1 {
	line-height: $h1-line-height;
	@include media-breakpoint-up(md) {
		margin-bottom: 0.48em;
	}
}

h2, .h2 {
	line-height: $h2-line-height;
	color: $black;
}

h3, .h3 {
	line-height: $h3-line-height;
}

h4, .h4 {
	line-height: $h4-line-height;
}

h5, .h5 {
	color: var(--vc-black);
	line-height: $h5-line-height;
}

h6, .h6 {
	line-height: $h6-line-height;
}

p, ul, ol, li, label {
	margin-bottom: 0;
	font-size: 12px;

	@include media-breakpoint-up(sm) {
		font-size: 14px;
	}
}

p {
	margin-bottom: 8px;
}

address {
	font-size: 12px;

	@include media-breakpoint-up(sm) {
		font-size: 14px;
	}
}

.text--headline-lg {
	color: $red;
	font-family: Rasa,Lora,"Noto Serif",Times New Roman,serif;
	font-size: 1.875rem;
	font-weight: 500;
	line-height: 1.07692;
}

.u-gradient {
	background-image: linear-gradient(transparent, transparent),
	linear-gradient(transparent, transparent),
	linear-gradient(to right, $primary-light, $primary-dark);
	background-repeat: no-repeat;
	background-position: 120%, 122%, 0 122%;
	background-size: 100% 6px;
}

.footnote {
	font-size: 14px;
	font-style: italic;
}

.btn-link {
	border: none;
	border-bottom: 1px solid transparent;
	background-color: transparent;
	color: var(--vc-secondary);
	font-weight: 700;
	padding: 0;
	transition: all 0.25s ease-in-out;
	word-wrap: normal;

	&:hover, &:focus, &:active {
		color: var(--vc-primary);
		border-bottom: 1px solid var(--vc-primary);
		transition: all 0.25s ease-in-out;
	}
}
