@property --p{
	syntax: '<number>';
	inherits: true;
	initial-value: 0;
}

.pie {
	--w: 150px;
	position: relative;
	width: var(--w);
	aspect-ratio: 1;
	display: inline-grid;
	place-content: center;
	margin: 5px;
	font-size: 25px;
	font-weight: bold;
	font-family: sans-serif;
	transform: translateX(-50%);
	left: 50%;
	background-color: $slate-050;
	border-radius: 50%;

	&:before, &:after {
		content: "";
		position: absolute;
		border-radius: 50%;
	}

	&:before{
		inset: 0;
		background: conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
		//background:
		//				radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
		//				conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
		//-webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
		//mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
		z-index: -1;
	}

	&:after {
		//inset:calc(50% - var(--b)/2);
		background:var(--c);
		transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
	}
}

.animate {
	animation:p 1.5s ease-in .5s 1 normal both;
}

@keyframes p {
	from{--p:0}
}