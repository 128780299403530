// Phase 2 Styles
#requiredDocsButtonDiv, #singleDocType, #multipleDocTypes {
	display: none;
	&.show {
		display: block;
	}
}

#verifyIdentity {
	.card-body {
		transition: all 0.5s ease-in-out;
	}
}

//#viewAndSubmitFile {
//	display: none;
//
//	&.show {
//		display: block;
//	}
//}

.file-group {
	padding: 0;
}

#multipleDocTypeFiles {
	form {
		background-color: var(--vc-gray-100);
	}
}

#verifyIdentityTop, #chooseVerification, #viewAndSubmitFile,
#verifyIdentity, #documentsInReview, #uploadFormDiv,
#uploadMessage, #processSection {
	display: block;
	opacity: 1;

	&.hidden {
		display: none;
	}
	&.showing {
		display: block;
		opacity: 0;
		transition: opacity .5s ease;
	}

	&.shown {
		transition: opacity .5s ease;
	}
}

// Web Components (normally the styles would go into the component class
//multi-submit-button {
//	display: none;
//	&.show {
//		display: block;
//	}
//}



// Phase 1  Styles
.shipped {
	display: flex;
	padding-top: 20px;
}

.shipped__logo {
	margin-right: 15px;
	padding-top: 3px;
}

.shipped__logo--ups {
	img {
		width: 40px;
		height: 45px;
	}
}

.shipped__logo--mail {
	img {
		width: 48px;
		height: 29px;
	}
}

.shipped__logo--will-call {
	svg {
		height: 34px;
		color: var(--vc-secondary);
	}
}