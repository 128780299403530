.form-check {
	&.success {
		.form-check-input {
			&:checked {
				background-color: $success;
				border-color: $success;
			}
			&:focus {
				border-color: $success;
				box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%);
				outline: 0;
			}
		}
	}
}