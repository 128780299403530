// @TODO New styles for refresh
section {
	padding: 10px 0;

	@include media-breakpoint-up(sm) {
		padding: 20px 0;
	}
}

.section__header--center {
	text-align: center;
}

.section__content {
	margin-bottom: 5px;
}

.section--agencies {
	h2 {
		color: $secondary;
		font-size: 18px;
		margin-bottom: 0;

		@include media-breakpoint-up(sm) {
			font-size: $h2-font-size;
			margin-bottom: 8px;
		}
	}

	p {
		font-size: 14px;
		margin-bottom: 0;

		@include media-breakpoint-up(sm) {
			margin-bottom: 5px;
			font-size: 18px;
		}
	}
}

.section--customer-status {
	padding-top: 0;
	padding-bottom: 0;
	background-color: var(--vc-gray-100);
}

.section--documents-in-review {
	padding-top: 0;
}

.section--order-cert {
	position: fixed;
	background-color: var(--vc-white)!important;
	padding-right: 0;
	padding-left: 0;
	bottom: 0;
	z-index: 100;
	box-shadow: 0px -2px 2px rgba(26, 36, 52, 0.2);

	@include media-breakpoint-up(sm) {
		position: relative;
		display: block;
		background-color: var(--vc-gray-100)!important;
		box-shadow: none;
	}

	@include media-breakpoint-only(sm) {
		padding-right: 100px;
		padding-left: 100px;
	}

	@include media-breakpoint-only(md) {
		padding-right: 50px;
		padding-left: 50px;
	}

	@include media-breakpoint-up(lg) {
		padding-right: 100px;
		padding-left: 100px;
	}

	.section--order-cert__title {
		position: relative;
		color: var(--vc-primary);
		margin-bottom: 16px;
		font-size: 13px;
		font-weight: 700;
		text-align: center;
		overflow: hidden;
		padding: 0 20px;

		@include media-breakpoint-up(sm) {
			margin-bottom: 24px;
			font-size: 22px;
		}

		>span {
			position: relative;
			display: inline-block;
		}

		& >span:before, & >span:after {
			content: '';
			position: absolute;
			top: 50%;
			border-bottom: 2px solid var(--vc-gray-500);
			width: 100vw;
			margin: 0 20px;
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}

		& >span:before {
			right: 100%;
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
		& >span:after {
			left: 100%;
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
	}

	.card {
		padding: 0;

		@include media-breakpoint-down(sm) {
			box-shadow: none;
			border: none;
		}

		@include media-breakpoint-up(sm) {
			padding: 10px;
		}
	}

	.card--product {
		display: block;
		transition: all 0.3s;

		@include media-breakpoint-up(sm) {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			text-align: left;
		}

		h3 {
			margin-bottom: 0;
			font-size: 10px;
			text-transform: uppercase;

			@include media-breakpoint-up(sm) {
				font-size: 13px;
			}
		}

		svg.svg-inline--fa * {
			fill: $black;
		}

		.svg-inline--fa {
			transition: transform 0.5s ease-in-out;
			transform: translateX(0);
			font-size: 32px;
			margin-top: 0;

			@include media-breakpoint-up(sm) {
				font-size: 36px;
			}
		}

		.card-body {
			display: block;
			text-align: center;
			font-size: 10px;
			padding: 0;

			@include media-breakpoint-up(sm) {
				display: flex;
				align-items: center;
				padding: 0;
				flex: none;
				flex-direction: row;
			}
		}

		.card-title {
			display: block;
			margin-left: 0;
			@include media-breakpoint-up(sm) {
				display: inline-flex;
				margin-left: 10px;
			}
		}

		.card-footer {
			display: none;

			@include media-breakpoint-up(sm) {
				display: block;
				border-top: none;
				background-color: #FFFFFF;
				padding: 0;
			}

			.btn-arrow {
				padding: 6px 7px;
				border-radius: 50px;

				svg.svg-inline--fa * {
					fill: #ffffff;
				}

				.svg-inline--fa {
					font-size: 18px;
				}

				&.btn-arrow--to-red {
					@extend %vcgradient-secondary;
					width: 34px;
					line-height: 1;
					color: #FFFFFF;
					white-space: nowrap;
					transition: width 0.5s ease-in-out, padding 0.5s ease-in-out, background 1s ease-in-out, color 0.5s ease-in-out;
				}
			}
		}

		&:hover, &:focus {
			transition: all 0.3s;
			transform: scale(1);
			border: 1px solid rgba(0, 0, 0, 0.125); // replaces the border from the a selector

			svg.svg-inline--fa * {
				fill: url(#lg-red);
			}

			h3 {
				color: $primary;
				transition: color 0.3s;
			}

			.card-footer {
				.btn-arrow {
					&.btn-arrow--to-red {
						@extend %vcgradient-primary;
						border: 1px solid var(--vc-primary);
						background-position: 90%;
						transition: none;

						&:hover, &:focus, &:active, &:active:focus {
							border: 1px solid var(--vc-primary);
						}
					}

					svg {
						transform: translateX(0); // This fixes jerky animation of the arrow
					}
				}
			}
		}
	}
}

.section--process {
	padding-top: 0;
}

// End New Styles for Refresh

// Old styles (remove as new pages are reworked
.sec {
	position: relative;
	padding: 50px 0;

	@include media-breakpoint-down(sm) {
		padding: 45px 0;
	}
}

.sec--agency {
	background-color: #fff;
}

.sec--agency-about {
	strong > em {
		font-size: 12px;
	}
}

.sec--agencies {
	h2 {
		color: var(--vc-secondary);
	}
}

.sec--bg-four {
	padding: 160px 0;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 75%;
	background-image: url("../../images/atomic/banners/delivery.jpg");
	z-index: 0;

	@include media-breakpoint-down(sm) {
		padding: 45px 0;
	}

	@include media-breakpoint-between(sm, md) {
		padding: 90px 0;
	}

	@include media-breakpoint-down(md) {
		background-image: none;
	}

	@include media-breakpoint-up(lg) {
		background-position: 100%;
	}

}

.sec--certpicker {

	h2 {
		font-size: $h3-font-size;
		margin-bottom: 52px;
		color: #FFFFFF;
	}

	.card--product {
		@include media-breakpoint-down(lg) {
			margin-bottom: 20px;
		}
	}

	.row {
		@include media-breakpoint-down(lg) {
			align-items: center;
		}
	}
}

.sec--crba-send {
	.card-footer {
		background-color: transparent;
		border-top: none;
		padding-bottom: 16px;

		address {
			margin-bottom: 0;
		}
	}
}

// Timing Pricing Results
.sec--estimate-header {
	background-color: var(--vc-gray-100);

	h1 {
		display: flex;
		align-items: center;
	}

	.svg-inline--fa {
		font-size: 56px;
		margin-right: 25px;
	}
}

.sec--timing-pricing-results {
	padding-top: 20px;
}
// END Timing Pricing Results

.sec--relationships {
	h2 {
		color: var(--vc-secondary);
	}

	.spread {
		margin-top: 30px;
		padding-left: 0;
		display: flex;
		flex-direction: column;
		list-style: none;
		font-size: 18px;
		font-weight: 700;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		@include media-breakpoint-up(lg) {
			font-size: 22px;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
}

//START Select Express Courier Services
.sec--save-on-shipping {
	.fa-ups {
		font-size: 200px;
	}
}

//END Select Express Courier Services

.sec--state {
	h3 {
		font-size: $h5-font-size;

		@include media-breakpoint-down(sm) {
			font-size: 22px;
		}
	}
}

// HOME PAGE
.sec--step {
	position: relative;

	h2 {
		font-size: $h3-font-size;
	}

	.step {
		position: relative;
		@include media-breakpoint-up(md) {
			padding-left: 64px;
		}
	}

	.fa-step- {
		&one, &two, &three, &four {
			color: $secondary-dark;
		}
	}
	.home-step-number {
		@include media-breakpoint-up(md) {
			  @include visually-hidden;
		}
	}
}

#stepTwo {
	.fa-step-two {
		font-size: 101px;
		left: -83px;
		opacity: .1;
		position: absolute;
		top: -23px;

		@include media-breakpoint-between(md, lg) {
			font-size: 190px;
			left: -9%;
			top: -77px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 413px;
			position: absolute;
			left: -144px;
			opacity: 0.10;
			top: -77px;
		}
	}
}

#stepThree {
	.fa-step-three {
		font-size: 101px;
		left: -83px;
		opacity: .1;
		position: absolute;
		top: -23px;

		@include media-breakpoint-between(md, lg) {
			font-size: 190px;
			left: -9%;
			top: -77px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 413px;
			left: -116px;
			top: -77px;
		}
	}
}

#stepFour {
	.fa-step-four {
		font-size: 101px;
		left: -83px;
		opacity: .1;
		position: absolute;
		top: -23px;

		@include media-breakpoint-between(md, lg) {
			font-size: 190px;
			left: -20%;
			top: -77px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 413px;
			left: -116px;
			top: -77px;
		}
	}
}

.sec--trust {

	h3 {
		margin-bottom: 35px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			text-align: left;
		}
	}

	.sideby-col {
		font-size: 16px;

		@include media-breakpoint-down(sm) {
			padding-right: 0;
		}

		p {
			margin-top: 8px;
			margin-bottom: 8px;
		}
	}

	.sideby-col + .sideby-col {

	}
}

.sec--funeral-home {

	h2 {
		font-size: $h3-font-size;
	}

	.checks {
			span {
				color: $red;
				font-family: $font-family-serif;
				font-size: 30px;
				font-weight: 500;
				line-height: 1.07692;
			}
		}
}

.sec--funeral-home {
	h2 {
		font-size: $h2-font-size;
	}
}

// Timing and PRicing
.sec--timing-pricing {
	.sec__topper {
		display: flex;
		margin-bottom: 20px;
		align-items: center;

		@include media-breakpoint-up(md) {
			display: block;
			//margin-bottom: 20px;
		}
		h3 {
			@include media-breakpoint-down(md) {
				margin-left: 20px;
			}
		}

		.sec__icon {
			margin-top: 0;
			width: 45px;
			height: 53.625px;

			@include media-breakpoint-up(md) {
				width: 100px;
				height: 119.188px;
			}
		}
	}
}

.sec--timing-pricing-results {
	.fa-ups, .fa-usps {
		position: relative;
		height: 1.5em;
	}

	.fa-ups {
		top: -5px;
	}

	.fa-usps {
		top: -12px;
	}
}


// Why VitalChek?
#whyFastBody2 {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.sec--whyauthorized, .sec--whyexperienced, .sec--whytransparent, .sec--whysecure, .sec--whyfast {
	@include media-breakpoint-down(md) {
		padding: 10px 0;
		background-color: $slate-050;
	}
}

.sec--whyauthorized {
	@include media-breakpoint-down(md) {
		padding: 40px 0 10px;
	}
}

.sec--whyfast {
	@include media-breakpoint-down(md) {
		padding: 10px 0 40px;
	}
}

.sec--whysecure {
	@include media-breakpoint-up(lg) {
		padding: 120px 0;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url("../../images/atomic/banners/coffeecomputer.jpg");
		z-index: 0;
	}
}

.sec--whytransparent {
	.card {
		border: none;
		border-radius: 0;
		box-shadow: none;
		background-color: transparent;
	}

	.card-title {
		padding: 16px;
	}

	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}

.sec--whytransparent__types {
	background-color: $white;
	border: 1px solid $border-color;
	border-radius: 4px;
	box-shadow: $box-shadow;
}

.sec--whyauthorized__title, .sec--whyexperienced__title, .sec--whytransparent__title, .sec--whysecure__title, .sec--whyfast__title {
	h2 {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.sec--whyauthorized__body, .sec--whyexperienced__body, .sec--whytransparent__body, .sec--whysecure__body, .sec--whyfast__body {
	&.collapse:not(.show) {
		@include media-breakpoint-up(md) {
			display: block;
		}
	}
}

// @TODO New sections for refresh
.section--how-vc-works {
	background-color: var(--vc-white);
	overflow: hidden;
	padding-top: 40px;
	padding-bottom: 40px;

	h2 {
		text-align: left;
		font-size: 18px;

		@include media-breakpoint-up(sm) {
			text-align: center;
		}

		@include media-breakpoint-between(sm, lg) {
			font-size: 24px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 28px;
		}

		.svg-inline--fa {
			font-size: 40px;
			vertical-align: middle;
		}
	}

	.step {
		position: relative;
		padding: 30px 15px 15px 15px;
		border-radius: 10px;
		background-color: $gray-100;

		@include media-breakpoint-up(md) {
			padding: 15px;
		}
	}

	.step__content {
		@include media-breakpoint-up(md) {
			padding-left: 60px;
		}
	}

	.step__number {
		position: absolute;
		display: flex;
		align-items: center;
		width: 100px;
		height: 50px;
		top: -35px;
		left: 0;
		background-color: #ffffff;
		border: 4px solid var(--vc-secondary);
		border-radius: 10px 0 0 10px;

		@include media-breakpoint-up(md) {
			width: 100px;
			height: 75px;
			left: -48px;
			top: 21px;
		}
	}

	.step__number--number {
		width: 50%;
		height: 100%;
		color: #ffffff;
		font-size: 30px;
		font-weight: 700;
		line-height: 1.4;
		background-color: var(--vc-secondary);
		text-align: center;

		@include media-breakpoint-up(md) {
			font-size: 36px;
			line-height: 1.5;
		}

		span {
			position: relative;
			display: block;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.step__number--icon {
		width: 50%;
		height: 100%;
		text-align: center;

		svg {
			position: relative;
			height: 25px;
			width: auto;
			top: 50%;
			transform: translateY(-50%);

			@include media-breakpoint-up(md) {
				height: 30px;
			}
		}
	}

	.btn-link {
		display: flex;
		align-items: center;
		border: none;
		&:hover, &:focus, &:active {
			//color: $secondary;
			border: none;
		}

		svg {
			height: 22px;
			position: relative;
			top: -1px;

			&.bump-up-1 {
				top: 1px!important;
			}
		}
	}

	.card {
		align-items: flex-start;
		box-shadow: none;
	}

	.step__read-more {
		margin-top: 0;

		&:before {
			content: "";
			width: 100%;
			height: 2px;
			padding: 0 20px;
			background-color: $gray-300;
			display: inline-block;
			position: relative;
			right: 0;
			bottom: -35px;
		}
	}
}

.section--reviews{
	background-color: var(--vc-white);

	.carousel {
		padding: 20px;
		background-color: $secondary;
		border-radius: 10px;
		color: #FFFFFF;
		text-align: center;
	}

	.carousel-item {
		transition: transform .6s ease-in-out;
	}
	.carousel-indicators{
		position: relative;
		margin-bottom: 0;
	}
	.carousel-indicators [data-bs-target]{
		width: 8px; /* change width */
		height: 8px; /* change height */
		border-radius: 100%; /* add border-radius */
	}

	.carousel-item__commenter {
		margin-bottom: 25px;

		p {
			font-weight: 700;
			font-size: 16px;
			font-style: italic;
		}

	}

	.carousel-item__commenter--sm {
		margin-bottom: 25px;

		p {
			font-weight: 400;
			font-size: 12px;
			font-style: italic;
		}
	}

	.carousel-item__review {
		padding: 0;
		p {
			font-size: 13px;
			font-weight: 400;
		}

		@include media-breakpoint-up(lg) {
			padding: 0 75px;
		}
	}

	.carousel-item__shopper-approved {
		margin-bottom: 25px;
	}

	.carousel-item__stars {
		margin: 15px 0 5px;
		font-size: 22px;
		color: $warning;
	}
}
